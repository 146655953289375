import { DownArrowIcon, ItasMinusIcon, PlusIcon, RightArrowIcon, UpArrowIcon } from 'src/assets'
import CustomerDetails from 'src/ui/fragments/customer-details/customer-details'
import { Button, InputCheckbox, InputSelect, InputText, Modal } from 'src/ui/widgets'

import { PolicyType, useOfficeStudiosForm } from './offices-studios-form.hook'

const OfficesStudiosForm = () => {
  const {
    t,
    formik,
    validationSchema,
    selectedCard,
    setSelectedCard,
    sedi,
    showMoreInfo,
    setShowMoreInfo,
    addCompanyForm,
    removeCompanyForm,
    companies,
    handleCompanyChange,
    getSedeDetails,
    showSuccessModal,
    handleSuccessModalClose,
    modalMessage,
  } = useOfficeStudiosForm()
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleBlur,
    handleChange,
  } = formik
  const Header = (
    <div className="col-md-5">
      <h2 className="text-left font-48-light c-292929">{t('offices_studios.form.title')}</h2>
      <div className="mt-4 align-items-center text-left font-18-roman c-646464">
        <p>{t('offices_studios.form.subtitle')}</p>
      </div>
      <div className="mt-4 align-items-center text-left font-18-roman c-646464">
        <p>{t('offices_studios.form.subtitle2')}</p>
      </div>
    </div>
  )

  const SilverCard = (
    <div className="col-md-4 pt-3">
      <div
        onClick={() => {
          setSelectedCard(PolicyType.SILVER)
          setFieldValue('selectedPolicy', PolicyType.SILVER)
        }}
        className={`warranty-card same-height ${
          selectedCard === PolicyType.SILVER ? 'active' : ''
        }`}
      >
        <h4 className="font-24-bold">{t('offices_studios.form.card_section.silver_card.title')}</h4>
        <div className="d-md-flex">
          <p className="font-24-bold me-1">
            {t('offices_studios.form.card_section.silver_card.price')}
          </p>
          <p className="font-24"> {t('offices_studios.form.card_section.common_card_text')}</p>
        </div>
        <hr />
        <p className="font-16">
          {t('offices_studios.form.card_section.silver_card.warranties.title')}
        </p>
        <ul>
          <li>{t('offices_studios.form.card_section.silver_card.warranties.warranty1')}</li>
          <li>{t('offices_studios.form.card_section.silver_card.warranties.warranty2')}</li>
          <li>{t('offices_studios.form.card_section.silver_card.warranties.warranty3')}</li>
          <li>{t('offices_studios.form.card_section.silver_card.warranties.warranty4')}</li>
        </ul>
      </div>
    </div>
  )
  const GoldCard = (
    <div className="col-md-4 pt-3">
      <div
        onClick={() => {
          setSelectedCard(PolicyType.GOLD)
          setFieldValue('selectedPolicy', PolicyType.GOLD)
        }}
        className={`warranty-card same-height ${selectedCard === PolicyType.GOLD ? 'active' : ''}`}
      >
        <div>
          <h4 className="font-24-bold">{t('offices_studios.form.card_section.gold_card.title')}</h4>
          <div className="d-md-flex">
            <p className="font-24-bold me-1">
              {t('offices_studios.form.card_section.gold_card.price')}
            </p>
            <p className="font-24"> {t('offices_studios.form.card_section.common_card_text')}</p>
          </div>
        </div>
        <hr />
        <p className="font-16">
          {t('offices_studios.form.card_section.gold_card.warranties.title')}
        </p>
        <ul>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty1')}</li>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty2')}</li>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty3')}</li>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty4')}</li>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty5')}</li>
          <li>{t('offices_studios.form.card_section.gold_card.warranties.warranty6')}</li>
        </ul>
      </div>
    </div>
  )

  const CustomCard = (
    <div className="col-md-4 pt-3">
      <div
        onClick={() => {
          setSelectedCard(PolicyType.CUSTOM)
          setFieldValue('selectedPolicy', PolicyType.CUSTOM)
        }}
        className={`warranty-card same-height ${
          selectedCard === PolicyType.CUSTOM ? 'active' : ''
        }`}
      >
        <h4 className="font-24-bold">{t('offices_studios.form.card_section.custom_card.title')}</h4>
        <hr />
        <p className="font-16">{t('offices_studios.form.card_section.custom_card.price')}</p>
      </div>
    </div>
  )

  const CardSection = (
    <>
      <h3 className="text-uppercase c-7f7f7f font-12-bold mb-3">
        {t('offices_studios.form.card_section.label')}
      </h3>
      <p className="font-16">{t('offices_studios.form.card_section.description')}</p>
      <div className="row justify-content-between">
        {SilverCard}
        {GoldCard}
        {CustomCard}
      </div>
    </>
  )

  const CompanySelection = (
    <div>
      <h3 className="text-uppercase c-7f7f7f font-12-bold mb-3">
        {t('offices_studios.form.company_selection.label')}
      </h3>
      <div className="row">
        <div style={{ width: '49%' }}>
          <InputSelect
            name="company"
            label={t('offices_studios.form.company_selection.input_label')}
            options={sedi.map((sede) => ({
              id: sede.code.toString(),
              value: sede.description,
            }))}
            validationSchema={validationSchema}
            setFieldValue={(field: string, value: string, shouldValidate?: boolean) => {
              getSedeDetails(value)
              return setFieldValue(field, value, shouldValidate)
            }}
            setFieldTouched={setFieldTouched}
            classesLabel="font-14-roman c-7f7f7f"
            touched={touched.company}
            value={values.company}
            error={errors.company}
            classesInput="offices-input font-16-roman"
          />
        </div>
      </div>
    </div>
  )

  const CompanyData = (index: number) => (
    <div key={index} className="mb-4">
      <h3 className="text-uppercase c-7f7f7f font-12-bold mb-3">
        {t('offices_studios.form.company.label')}
      </h3>
      <div className="row mb-4">
        <div className="col-md-6">
          <InputText
            name={`companies[${index}].companyName`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'companyName', e.target.value)
            }
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.companyName}
            value={values?.companies?.[index]?.companyName}
            disableIcon={true}
            error={
              typeof errors?.companies?.[index] === 'object' &&
              touched?.companies?.[index]?.companyName
                ? errors?.companies?.[index]?.companyName
                : ''
            }
            placeholder={t('offices_studios.form.company.company_name')}
          />
        </div>
        <div className="col-md-6">
          <InputText
            name={`companies[${index}].vatNumber`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'vatNumber', e.target.value)
            }
            type="number"
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.vatNumber}
            value={values?.companies?.[index]?.vatNumber}
            disableIcon={true}
            error={
              typeof errors?.companies?.[index] === 'object' &&
              touched?.companies?.[index]?.vatNumber
                ? errors?.companies?.[index]?.vatNumber
                : ''
            }
            placeholder={t('offices_studios.form.company.vat_number')}
          />
        </div>
      </div>
      <div className="row mb.4">
        <div className="col-md-4">
          <InputText
            name={`companies[${index}].address`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'address', e.target.value)
            }
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.address}
            value={values?.companies?.[index]?.address}
            disableIcon={true}
            error={
              typeof errors?.companies?.[index] === 'object' && touched?.companies?.[index]?.address
                ? errors?.companies?.[index]?.address
                : ''
            }
            placeholder={t('offices_studios.form.company.address')}
          />
        </div>
        <div className="col-md-2">
          <InputText
            name={`companies[${index}].zipCode`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'zipCode', e.target.value)
            }
            type="number"
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.zipCode}
            value={values?.companies?.[index]?.zipCode}
            disableIcon={true}
            classes="oferflow-hidden"
            error={
              typeof errors?.companies?.[index] === 'object' && touched?.companies?.[index]?.zipCode
                ? errors?.companies?.[index]?.zipCode
                : ''
            }
            placeholder={t('offices_studios.form.company.zip_code')}
          />
        </div>
        <div className="col-md-4">
          <InputText
            name={`companies[${index}].city`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'city', e.target.value)
            }
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.city}
            value={values?.companies?.[index]?.city}
            disableIcon={true}
            error={
              typeof errors?.companies?.[index] === 'object' && touched?.companies?.[index]?.city
                ? errors?.companies?.[index]?.city
                : ''
            }
            placeholder={t('offices_studios.form.company.city')}
          />
        </div>
        <div className="col-md-2">
          <InputText
            name={`companies[${index}].province`}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCompanyChange(index, 'province', e.target.value)
            }
            handleBlur={handleBlur}
            validationSchema={validationSchema}
            touched={touched?.companies?.[index]?.province}
            value={values?.companies?.[index]?.province}
            disableIcon={true}
            classes="oferflow-hidden"
            error={
              typeof errors?.companies?.[index] === 'object' &&
              touched?.companies?.[index]?.province
                ? errors?.companies?.[index]?.province
                : ''
            }
            placeholder={t('offices_studios.form.company.province')}
          />
        </div>
      </div>
    </div>
  )

  const ConsentSection = (
    <div>
      <InputCheckbox
        name="consentInformativeSet"
        handleChange={handleChange}
        handleBlur={handleBlur}
        validationSchema={validationSchema}
        touched={touched?.consentInformativeSet}
        value={values?.consentInformativeSet}
        error={
          touched?.consentInformativeSet && errors.consentInformativeSet
            ? errors.consentInformativeSet
            : ''
        }
        label={t('offices_studios.form.consent_label')}
        classesLabel="m-0 font-16-bold d-flex align-items-center"
      >
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          <p className="m-0">{t('offices_studios.form.consent_description')}</p>
        </div>
      </InputCheckbox>
      <Button
        text={t('commons.show_more_info')}
        iconRight={showMoreInfo ? UpArrowIcon : DownArrowIcon}
        classesIconRight="ms-3 filter-7c2d35"
        iconSize={12}
        classes="p-md-0 d-flex bg-none"
        classesText="font-12-roman fw-bold c-7c2d35 text-uppercase"
        onPress={() => setShowMoreInfo(!showMoreInfo)}
      />
      {showMoreInfo && (
        <div className="ms-4 ps-1 font-14 c-7f7f7f">
          {t('offices_studios.form.info').split('link')[0]}
          <a
            href="#"
            className="c-7f7f7f"
            onClick={() =>
              window.open('https://www.gruppoitas.it/gruppo/note-legali-e-privacy', '_blank')
            }
          >
            link
          </a>
        </div>
      )}
    </div>
  )

  const ModalSuccess = (
    <Modal
      visible={showSuccessModal}
      classes="d-md-flex justify-content-center pb-5 pe-5 ps-5 pt-0"
      onClose={() => handleSuccessModalClose()}
    >
      <div>
        <div className="text-center ms-5 me-5">
          <div className="d-md-flex justify-content-center ms-5 me-5">
            <h1>{t('commons.thank_you')}</h1>
          </div>
          <p className="font-16">{modalMessage}</p>
        </div>
      </div>
    </Modal>
  )

  const Form = (
    <form onSubmit={handleSubmit} className="form-style">
      {CardSection}
      <hr className="mt-4 mb-4" />
      {CompanySelection}
      <hr className="mt-4 mb-4" />
      <CustomerDetails formik={formik} />
      <hr className="mt-4 mb-4" />
      {companies?.map((_, index) => {
        return CompanyData(index)
      })}
      <div className="d-flex">
        <Button
          text={t('commons.add')}
          iconRight={PlusIcon}
          classesIconRight="ms-3 filter-7c2d35"
          iconSize={12}
          flavor="transparentPrimary"
          classes={`m-0 mb-5 add-button-border`}
          classesText="font-12-roman fw-bold c-7c2d35 text-uppercase"
          onPress={() => addCompanyForm()}
        />
        {companies?.length > 1 && (
          <Button
            text={t('commons.remove')}
            iconRight={ItasMinusIcon}
            classesIconRight="ms-3 filter-7c2d35"
            iconSize={12}
            flavor="transparentPrimary"
            classes={`m-0 ms-2 mb-5 add-button-border`}
            classesText="font-12-roman fw-bold c-7c2d35 text-uppercase"
            onPress={() => removeCompanyForm()}
          />
        )}
      </div>
      {ConsentSection}

      <Button
        text={t('commons.send_request')}
        type="submit"
        classes="bg-7c2d35 p-4 justify-content-center mt-4"
        iconRight={RightArrowIcon}
        classesIconRight="filter-ffffff"
        classesText="text-uppercase font-16 c-ffffff me-3"
      />
    </form>
  )

  return (
    <div>
      {Header}
      {Form}
      {ModalSuccess}
    </div>
  )
}

export default OfficesStudiosForm

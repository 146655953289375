import './App.scss'

import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import { OutcomeRoot, WrapperRoot } from './roots'
import { LoaderRoot } from './roots/loader/loader.root'
import { NavigationRoot } from './roots/navigation/navigation.root'
import { ScrollTopRoot } from './roots/scroll-top/scroll-top.root'

const App = () => {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <WrapperRoot>
          <ScrollTopRoot />
          <LoaderRoot />
          <NavigationRoot />
          <OutcomeRoot />
        </WrapperRoot>
      </RecoilRoot>
    </BrowserRouter>
  )
}

export default App

import './customer-details.scss'

import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { usePatchMail } from 'src/networking/terminals'
import { atomFormikModal, selectorInsuredOfficesStudios } from 'src/storage'
import { Insured } from 'src/types'
import { Formik, FormikOfficesAndStudios, FormikPolicy } from 'src/types/formik'
import { regexPhone } from 'src/ui/pages/policy-configuration/sections/handlers/validation-schemas.hook'
import * as yup from 'yup'

export const useCustomerDetails = (
  formik: Formik<FormikPolicy> | Formik<FormikOfficesAndStudios>
) => {
  const { t } = useTranslation()
  const [insured, setInsured] = useRecoilState(selectorInsuredOfficesStudios)

  const [showEditMail, setShowEditMail] = useState(false)
  const [showEditPhone, setShowEditPhone] = useState(false)

  const { crossGate: patchMail } = usePatchMail()

  const validationSchema = yup.object({
    email: yup.string().email(t('commons.invalid_email')).required(t('commons.email_required')),
    phone: yup.string().matches(regexPhone, t('commons.invalid_phone')),
  })

  const formikModal = useRecoilValue(atomFormikModal)
  const modalFormik = useFormik({
    initialValues: formikModal,
    validationSchema,
    onSubmit: () => {
      // do nothing
    },
  })

  useEffect(() => {
    if (
      !(
        (insured && insured?.email === null) ||
        insured?.email === undefined ||
        insured?.email === ''
      )
    ) {
      formik.setFieldValue('email', insured?.email)
      modalFormik.setFieldValue('email', insured?.email)
    }
    if (
      !(
        (insured && insured?.telefono === null) ||
        insured?.telefono === undefined ||
        insured?.telefono === ''
      )
    ) {
      formik.setFieldValue('phone', insured?.telefono)
      modalFormik.setFieldValue('phone', insured?.telefono)
    }
  }, [insured])

  const closePhoneMailModal = () => {
    modalFormik.setFieldValue('email', insured?.email)
    modalFormik.setFieldValue('confirmMail', '')

    modalFormik.setFieldValue('phone', insured?.telefono)
    setShowEditMail(false)
    setShowEditPhone(false)
  }

  const setNewMail = (email: string) => {
    setShowEditMail(false)
    setInsured({ ...insured, email: email } as Insured)
    formik.setFieldValue('email', email)

    /* patchMail(
      {
        uriParams: {
          idPolizza: 'codice',
        },
        body: {
          email: email,
          idAnagrafica: insured?.idAssicurato?.toString() || '',
        },
      },
      {
        onSuccess: (response) => {
          setInsured({ ...insured, telefono: response.email } as Insured)
          formik.setFieldValue('email', response.email)
        },
      }
    ) */
  }

  const setNewPhone = (phone: string) => {
    setShowEditPhone(false)
    setInsured({ ...insured, telefono: phone } as Insured)
    formik.setFieldValue('phone', phone)
    /* patchMail(
      {
        uriParams: {
          idPolizza: 'codice',
        },
        body: {
          telefono: phone,
          idAnagrafica: insured?.idAssicurato?.toString() || '',
        },
      },
      {
        onSuccess: (response) => {
          setInsured({ ...insured, telefono: response.telefono } as Insured)
          formik.setFieldValue('phone', response.telefono)
        },
      }
    ) */
  }
  return {
    t,
    insured,
    showEditMail,
    setShowEditMail,
    setNewMail,
    showEditPhone,
    setShowEditPhone,
    setNewPhone,
    modalFormik,
    closePhoneMailModal,
  }
}
